import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CarouselLogos({ items }) {
  const sliderRef = useRef(null);

  const carouselItems = items.length < 20 ? [...items, ...items, ...items] : items;

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 40000,
    slidesToShow: 16,
    slidesToScroll: 999,
    autoplay: true,
    autoplaySpeed: 200,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 13,
        },
      },
      {
        breakpoint: 1525,
        settings: {
          slidesToShow: 10,
          speed: 30000,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 8,
          speed: 20000,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 4,
          speed: 10000,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
          speed: 8000,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <Slider ref={sliderRef} {...settings}>
        {carouselItems.map((item, index) => (
          <div key={index} className="carousel-item">
            <img width={98} height={50} src={item.image} alt={item.alt} style={{ objectFit: "contain" }} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CarouselLogos;
