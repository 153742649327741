import React from "react";
import { Box } from "@mui/material";

function Container({ children, ...rest }) {
  return (
    <Box
      {...rest}
      sx={{
        flexGrow: 1,
        minHeight: "100%",
        px: {
          xs: 2,
          sm: 4,
          md: 6,
        },
        maxWidth: (theme) => theme.spacing(120),
        margin: "0 auto",
        boxSizing: "border-box",
      }}
    >
      {children}
    </Box>
  );
}

export default Container;
